<template>
  <!-- 商品分类页面 -->
  <div class="container">
    <a-row>
      <a-col :span="24" class="right">
        <div class="right-content">
          <!-- 筛选 -->
          <div class="table-page-search-wrapper">
            <a-form layout="inline">
              <a-row :gutter="40">
                <a-col :md="6" :sm="24">
                  <!-- 状态 -->
                  <a-form-item>
                    <a-select v-model="queryParam.delFlag" allow-clear placeholder="请选择状态">
                      <a-select-option v-for="(item, index) in statusList" :key="index" :value="item.status">
                        {{ item.name }}
                      </a-select-option>
                      <a-icon slot="suffixIcon" type="search" />
                    </a-select>
                  </a-form-item>
                </a-col>
                <a-col :md="6" :sm="24">
                  <!-- 分类名称 -->
                  <a-form-item>
                    <a-input-search
                      placeholder="请输入分类名称"
                      v-model="queryParam.name"
                      @pressEnter="initTableData"
                    />
                  </a-form-item>
                </a-col>
                <a-col :md="6" :sm="24">
                  <a-button type="primary" @click="initTableData">搜索</a-button>
                  <a-button style="margin-left: 8px" @click="() => ((queryParam = {}), initTableData())">重置</a-button>
                </a-col>
              </a-row>
            </a-form>
          </div>
          <div class="table-operator">
            <a-button type="primary" @click="$refs.addForm.add()">添加分类</a-button>
          </div>
          <!-- 外层表格 -->
          <a-table
            style="font-size: 26px"
            :pagination="false"
            :columns="columns"
            :data-source="data"
            class="components-table-demo-nested"
            :expandRowByClick="true"
            rowKey="id"
            :loading="loading"
          >
            <!-- logo -->
            <!-- <span slot="logo" slot-scope="text, record">
              <img :src="record.logo" alt="">
            </span> -->
            <span slot="name" slot-scope="text" style="font-weight: 600">
              {{ text }}
            </span>
            <!-- logo -->
            <span slot="logo" slot-scope="text, record">
              <img :src="record.logo" alt="" />
            </span>
            <!-- 是否公用 -->
            <span slot="isPublic" slot-scope="text" style="font-weight: 600">
              {{ text == 1 ? '是' : text == 2 ? '否' : '' }}
            </span>
            <span slot="sort" slot-scope="text" style="font-weight: 600">
              {{ text }}
            </span>
            <!-- 操作 -->
            <span slot="action" slot-scope="text, record" style="font-weight: 600">
              <a @click.stop="$refs.editForm.edit(record)">编辑</a>
              <a-divider type="vertical" />
              <a-popconfirm placement="topRight" title="确认删除？" @confirm="() => sysItemDelete(record)">
                <a @click.stop="">删除</a>
              </a-popconfirm>
            </span>
            <!-- 更新时间 -->
            <span slot="updateTime" slot-scope="text" style="font-weight: 600">
              {{ text ? text : '未修改' }}
            </span>
            <!-- 分佣比例 -->
            <span slot="ratio" slot-scope="text" style="font-weight: 600">
              {{ text ? text.toFixed(2) : '未设置' }}
            </span>
            <span slot="agentRatio" slot-scope="text" style="font-weight: 600">
              {{ text ? text.toFixed(2) : '未设置' }}
            </span>

            <span slot="bondPriceStr" slot-scope="text, record" style="font-weight: 600">
              {{ text }}
              <span v-if="record.maxBondPrice && record.maxBondPrice > record.bondPrice">
                ~ {{ record.maxBondPrice }}</span
              >
            </span>
            <!-- 状态 -->
            <span slot="displayStatus" slot-scope="text, record">
              <a-tag style="padding: 3px 10px" :color="record.delFlag == 1 ? '#87d068' : '#f50'">
                <span style="font-weight: 600">
                  {{ record.delFlag == 1 ? '启用' : '禁用' }}
                </span>
              </a-tag>
            </span>
            <!-- 内层表格 -->
            <a-table
              slot="expandedRowRender"
              slot-scope="record"
              :columns="innerColumns"
              rowKey="id"
              bordered
              :data-source="record.childrenList"
              :pagination="false"
            >
              <!-- 内层表格顶栏插槽 -->
              <span style="color: #999" slot="nameTitle">分类名称</span>
              <span style="color: #999" slot="logoTitle">分类图标</span>
              <span style="color: #999" slot="displayStatusTitle">显示状态</span>
              <span style="color: #999" slot="updateTimeTitle">修改时间</span>
              <span style="color: #999" slot="sortTitle">排序</span>
              <span style="color: #999" slot="actionTitle">操作</span>
              <!-- 内层分类名称 -->
              <span style="color: #555" slot="name" slot-scope="text">
                {{ text }}
              </span>
              <!-- logo -->
              <span slot="logo" slot-scope="text, record">
                <img :src="record.logo" alt="" />
              </span>
              <!-- 操作 -->
              <span slot="action" slot-scope="text, record">
                <a @click="$refs.editForm.edit(record)">编辑</a>
                <a-divider type="vertical" />
                <a-popconfirm placement="topRight" title="确认删除？" @confirm="() => sysItemDelete(record)">
                  <a>删除</a>
                </a-popconfirm>
                <a-divider type="vertical" />
                <router-link :to="{ path: '/bindParamsName', query: { goodsCategoryId: record.id } }"
                  >绑定参数名</router-link
                >
                <a-divider type="vertical" />
                <a @click="$refs.copyForm.edit(record.id)">复制参数名</a>
              </span>
              <!-- 更新时间 -->
              <span slot="updateTime" slot-scope="text">
                {{ text ? text : '未修改' }}
              </span>
              <!-- 状态 -->
              <span slot="displayStatus" slot-scope="text, record">
                <a-tag style="padding: 3px 8px" :color="record.delFlag == 1 ? 'green' : 'red'">
                  {{ record.delFlag == 1 ? '启用' : '禁用' }}
                </a-tag>
              </span>
            </a-table>
          </a-table>
          <!-- 分页组件 -->
          <div style="margin-top: 20px; display: flex; flex-direction: row-reverse">
            <a-pagination
              :pageSize="page.pageSize"
              :current="page.pageNo"
              show-size-changer
              :page-size-options="['10', '20', '50', '100']"
              show-quick-jumper
              :total="total"
              :show-total="(total) => `共 ${total} 条`"
              @change="pageChange"
              @showSizeChange="sizeChange"
            ></a-pagination>
          </div>
        </div>
      </a-col>
    </a-row>

    <add-form ref="addForm" @ok="initTableData" />
    <edit-form ref="editForm" @ok="getList" />
    <copy-form ref="copyForm" @ok="getList" />
  </div>
</template>
<script>
import { findCommodityClassPageList, commodityClassDelete } from '@/api/modular/mallLiving/commodity/commodityClass'

import addForm from './addForm'
import editForm from './editForm'
import copyForm from './copyParamsName'

const columns = [
  {
    title: '序号',
    align: 'left',
    width: '80px',
    key: 'number',
    customRender: (text, record, index) => `${index + 1}`,
  },
  {
    title: '分类名称',
    align: 'left',
    dataIndex: 'name',
    key: 'name',
    scopedSlots: {
      customRender: 'name',
    },
  },
  {
    title: '分类图标',
    align: 'center',
    dataIndex: 'logo',
    key: 'logo1',

    scopedSlots: {
      customRender: 'logo',
    },
  },
  {
    title: '显示状态',
    align: 'center',
    dataIndex: 'displayStatus',
    key: 'displayStatus',
    scopedSlots: {
      customRender: 'displayStatus',
    },
  },
  {
    title: '修改时间',
    align: 'center',
    dataIndex: 'updateTime',
    key: 'updateTime',
    scopedSlots: {
      customRender: 'updateTime',
    },
  },
  {
    title: '排序',
    align: 'center',
    dataIndex: 'sort',
    key: 'sort',
    scopedSlots: {
      customRender: 'sort',
    },
  },
  {
    title: '操作',
    align: 'center',
    dataIndex: 'action',
    width: '220px',
    key: 'action',
    scopedSlots: {
      customRender: 'action',
    },
  },
]

const innerColumns = [
  {
    align: 'center',
    key: 'name1',
    dataIndex: 'name',
    slots: {
      title: 'nameTitle',
    },
    scopedSlots: {
      customRender: 'name',
    },
  },
  {
    align: 'center',
    dataIndex: 'logo',
    key: 'logo1',
    slots: {
      title: 'logoTitle',
    },
    scopedSlots: {
      customRender: 'logo',
    },
  },
  {
    align: 'center',
    key: 'displayStatus1',
    dataIndex: 'displayStatus',
    slots: {
      title: 'displayStatusTitle',
    },
    scopedSlots: {
      customRender: 'displayStatus',
    },
  },
  {
    align: 'center',
    dataIndex: 'updateTime',
    key: 'updateTime1',
    slots: {
      title: 'updateTimeTitle',
    },
    scopedSlots: {
      customRender: 'updateTime',
    },
  },
  {
    align: 'center',
    key: 'sort1',
    dataIndex: 'sort',
    slots: {
      title: 'sortTitle',
    },
  },
  {
    align: 'center',
    dataIndex: 'action',
    width: '260px',
    key: 'action1',
    slots: {
      title: 'actionTitle',
    },
    scopedSlots: {
      customRender: 'action',
    },
  },
]

export default {
  data() {
    return {
      data: [],
      columns,
      loading: false,
      innerColumns,
      allList: [],
      innerData: [],
      page: {
        pageNo: 2,
        pageSize: 10,
      },
      total: 0, //总数据条数
      statusList: [
        {
          status: 1,
          name: '启用',
        },
        {
          status: 3,
          name: '禁用',
        },
      ],
      classList: [], //筛选商品分类列表项
      queryParam: {
        id: undefined, //商品分类id
        delFlag: undefined, //1显示；2删除；3隐藏
        name: '', //分类名称
      },
    }
  },
  mounted() {
    this.initTableData()
  },
  components: {
    addForm,
    editForm,
    copyForm,
  },
  methods: {
    //切换页面
    pageChange(pageNo, pageSize) {
      this.page.pageNo = pageNo
      this.page.pageSize = pageSize
      this.getList()
    },
    sizeChange(pageNo, pageSize) {
      this.page.pageNo = pageNo
      this.page.pageSize = pageSize
      this.getList()
    },
    //初始化页面
    initTableData() {
      this.page.pageNo = 1
      this.getList()
    },
    //获取列表数据
    getList() {
      this.loading = true
      const queryParam = {
        ...this.queryParam,
        page: this.page,
      }
      findCommodityClassPageList(queryParam)
        .then((res) => {
          if (res.success) {
            this.data = res.data.rows
            this.total = res.data.totalRows
            this.loading = false
          }
        })
        .finally(() => {
          setTimeout(() => {
            this.loading = false
          }, 5000)
        })
    },
    sysItemDelete(record) {
      commodityClassDelete({
        id: record.id,
        delFlag: '2',
      }).then((res) => {
        if (res.code == 200) {
          this.$message.success('删除成功')
          this.getList()
        }
      })
    },
  },
}
</script>
<style lang="less" scoped>
// @import url(); 引入公共css
.container {
  min-width: 1000px;
}

.title {
  color: rgba(16, 16, 16, 100);
  font-size: 14px;
  font-weight: bold;
}

.right {
  user-select: none;
  border-radius: 2px;
  background-color: rgba(255, 255, 255, 100);
}

.right-content {
  box-sizing: border-box;
  padding: 10px;
}

.icon {
  font-size: 20px;
  margin: 0 5px;
}

img {
  width: 50px;
  height: 50px;
}
</style>
